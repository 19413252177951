<template>
  <div class="container">
    <!-- <div class="back" @click="$router.replace(`/step/one/${$route.params.userId}/${$route.params.nickName}`)">
			<img src="https://oss.culturalcloud.net/furong/202210/302124275v99.png" alt="">
		</div> -->

    <el-dialog
      :close-on-click-modal="false"
      :title="foldTitle"
      :visible.sync="foldShow"
      width="20%"
      destroy-on-close
    >
      <el-form
        @submit.native.prevent
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="文件夹名称" prop="fileName">
          <el-input
            v-model="form.fileName"
            placeholder="请输入文件名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="saveFold('form')">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="请选择目标文件夹" :visible.sync="moveShow" width="30%">
      <el-tree
        :data="allFold"
        node-key="id"
        default-expand-all
        :props="defaultProps"
      >
        <div
          class="custom-tree-node"
          slot-scope="{ node, data }"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          "
        >
          <div>
            <i class="el-icon-folder-opened"></i>
            <span style="width: 200px"
              >{{ data.label }} （{{ data.key }}）</span
            >
          </div>
          <el-link
            type="primary"
            v-if="data.key != parentFolder"
            @click.stop="handleMove(data.key)"
            >移动
          </el-link>
        </div>
      </el-tree>

      <span slot="footer">
        <el-button @click="moveShow = false">取消</el-button>
        <!-- <el-button type="primary" @click="">OK</el-button> -->
      </span>
    </el-dialog>

    <back :prePage="prePage" disable @back="back" />
    <div class="file-list" v-loading="loading">
      <!-- {{$route.params.useId}} -->
      <div class="upload-template">
        <el-tooltip effect="dark" content="上传文件" placement="top">
          <div class="upload-file">
            <el-upload
              class="file-icon"
              drag
              :action="uploadFileUrl"
              :accept="accept"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
            >
              <!-- <i class="el-icon-circle-plus-outline"></i> -->
              <div class="file-icon" style="">
                <i
                  class="el-icon-circle-plus-outline"
                  style="font-size: 50px; color: #409eff"
                ></i>
                <span>将文件拖拽<br />至此处上传</span>
              </div>
            </el-upload>
            <div
              class="file-name"
              style="text-align: center; line-height: 40px"
            >
              上传文件
            </div>
          </div>
        </el-tooltip>
        <el-card class="box-card" style="border-bottom: 1px solid #d6dbe7">
          <div slot="header" class="clearfix">
            <span>模板文件</span>
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <div
              class="file"
              style="width: 70px; height: 100px; margin-right: 10px"
              v-for="(j, i) in templateFileList"
              :key="i"
            >
              <div
                class="file-icon"
                style="height: 70px; display: flex"
                @click.prevent="openFile(j)"
                :style="{
                  backgroundColor: j.folder ? 'transparent' : '',
                  boxShadow: j.folder ? 'none' : '',
                }"
              >
                <div v-if="['jpg', 'png', 'gif', 'jpeg'].includes(j.ext)">
                  <img
                    style="width: 100px"
                    :src="j.url"
                    object-fit="contain"
                    alt=""
                  />
                </div>
                <div v-else-if="j.folder">
                  <el-image
                    @dblclick="openFold(j.fileId)"
                    lazy
                    style="width: 100px; height: 100px"
                    src="https://cdn.eleadmin.com/20200609/ic_file_folder.png"
                    fit="cover"
                  >
                  </el-image>
                </div>

                <i style="font-size: 30px" v-else :class="getIcon(j.ext)"></i>
              </div>
              <div class="file-name" style="font-size: 13px">
                {{ j.fileName }}
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="nav" style="">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            @click.native="parentFolder != 0 ? getFilesData(0) : ''"
            >全部文件</el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-for="(item, index) in path"
            :key="item.fileId"
            @click.native="
              index != path.length - 1 ? getFilesData(item.fileId) : ''
            "
            >{{ item.fileName }}
            {{ index === path.length - 1 ? "(当前目录)" : "" }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="file-content">
        <div class="file-check">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选
          </el-checkbox>
          <div>
            <el-input
              v-model="search"
              style="width: 300px; margin-right: 10px"
              placeholder="请输入要搜索的文件名"
              size="normal"
              clearable
              @change="startSearch"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-button type="warning" icon="el-icon-plus" @click="addFold"
              >新建文件夹</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-rank"
              @click="getFile"
              :disabled="checkedCities.length === 0"
              >批量移动</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              :disabled="checkedCities.length === 0"
              @click="handleDelete()"
              >批量删除</el-button
            >
          </div>
        </div>
        <div class="file-box" v-if="fileList.length !== 0">
          <!-- <template></template> -->
          <!-- <el-divider></el-divider> -->
          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox v-for="(j, i) in fileList" :key="i" :label="j.fileId">
              <el-tooltip
                class="file"
                effect="dark"
                style="font-size: 20px"
                :content="j.fileName"
                placement="top"
                @contextmenu.prevent.native="openMenu(j, $event)"
              >
                <div>
                  <div
                    class="file-icon"
                    @click.prevent="openFile(j)"
                    :style="{
                      backgroundColor: j.folder ? 'transparent' : '',
                      boxShadow: j.folder ? 'none' : '',
                    }"
                  >
                    <div v-if="['jpg', 'png', 'gif', 'jpeg'].includes(j.ext)">
                      <img
                        style="width: 100px"
                        :src="j.url"
                        object-fit="contain"
                        alt=""
                      />
                    </div>
                    <div v-else-if="j.folder">
                      <el-image
                        @dblclick="openFold(j.fileId)"
                        lazy
                        style="width: 100px; height: 100px"
                        src="https://cdn.eleadmin.com/20200609/ic_file_folder.png"
                        fit="cover"
                      >
                      </el-image>
                    </div>

                    <i v-else :class="getIcon(j.ext)"></i>
                  </div>
                  <div class="file-name">
                    {{ j.fileName }}
                  </div>
                </div>
              </el-tooltip>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <el-empty description="暂无资料" v-else></el-empty>
      </div>
    </div>

    <ul
      v-show="visible"
      :style="{ left: left + 'px', top: top + 'px' }"
      class="contextmenu"
    >
      <li style="color: red" @click="handleDelete(selectedTag.fileId)">
        <i class="el-icon-delete"></i> 删除文件
      </li>
      <li style="color: orange" @click="handleEdit(selectedTag)">
        <i class="el-icon-edit"></i> 重命名
      </li>
      <li style="color: #409eff" @click="openPage(selectedTag.url)">
        <i class="el-icon-download"></i> 下载文件
      </li>
    </ul>
    <el-dialog
      :fullscreen="fullscreen"
      :destroy-on-close="true"
      :visible.sync="dialogVisible"
      width="90%"
      @close="fileUrl = ''"
    >
      <div slot="title">
        {{ title }}
        <el-button
          @click="fullscreen = !fullscreen"
          class="full-screen"
          type="text"
          ><i
            :class="
              fullscreen ? 'el-icon-copy-document' : 'el-icon-full-screen'
            "
          ></i
        ></el-button>
      </div>
      <iframe
        :src="fileUrl"
        frameborder="0"
        :style="{ width: '100%', height: fullscreen ? '100%' : '80vh' }"
      ></iframe>
    </el-dialog>

    <el-image-viewer
      v-if="showPreview"
      :urlList="previewImages"
      :on-close="closeViewer"
    ></el-image-viewer>
  </div>
</template>

<script>
import axios from "axios";
import {
  getFiles,
  delFile,
  saveCeaterFold,
  editFile,
  getAllfold,
  moveFile,
  getTemplateList,
} from "@/api";
import { Base64 } from "@/utils/base64.js";
import back from "@/components/back";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  name: "file",
  components: {
    back,
    ElImageViewer,
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      allFold: [],
      radio: null,

      moveShow: false,

      checkAll: false,
      checkedCities: [],
      cities: [],
      isIndeterminate: false,

      form: {},
      rules: {
        fileName: [
          {
            required: true,
            message: "请输入文件夹名称",
            trigger: "blur",
          },
        ],
      },
      parentFolder: 0,
      path: [],
      foldTitle: "",
      foldShow: false,
      search: "",
      prePage: "", //上一页路由
      fullscreen: false, //窗口全屏
      loading: false,
      fileList: [],
      title: "",
      dialogVisible: false,
      fileUrl: "",
      selectedTag: {},
      visible: false,
      top: 0,
      left: 0,
      accept: ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.gif,.jpg,.jpeg,.png",
      showPreview: false, //图片查看器
      previewImages: [], //图片
      uploadFileUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传的图片服务器地址
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 关闭进度条
      templateFileList: [], //模板文件列表
    };
  },
  //路由钩子
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prePage = from.fullPath;
    });
  },
  watch: {
    visible(value) {
      // console.log();
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
  },
  methods: {
    back() {
      if (this.path && this.path.length == 1) {
        this.getFilesData(0); //返回根文件夹
      } else if (this.path && this.path.length > 1) {
        this.getFilesData(this.path[this.path.length - 2].fileId); //返回上一级文件夹
      } else {
        this.$router.push(this.prePage); //返回上一页
      }
    },
    //获取模板文件列表
    getTemplateFileList() {
      getTemplateList().then((res) => {
        this.templateFileList = res.data;
      });
    },
    // fold目标文件夹
    handleMove(fold) {
      moveFile(fold, this.checkedCities).then((res) => {
        // console.log(res);
        this.moveShow = false;
        this.getFilesData(this.parentFolder);
        this.$message({
          message: "文件移动成功!",
          type: "success",
        });
      });
    },
    treeDataFun(data) {
      let arr = [];
      if (!(data && data.length > 0)) return [];
      data.forEach((item) => {
        arr.push({
          key: item.fileId,
          label: item.fileName,
          children: this.treeDataFun(item.subfolder),
        });
      });
      return arr;
    },

    getFile() {
      getAllfold({
        userId: this.$route.params.userId,
      }).then((res) => {
        // console.log(res);
        this.allFold = this.treeDataFun(res.data);
        this.allFold = [
          {
            key: 0,
            label: "根目录",
            children: this.allFold,
          },
        ];
        this.moveShow = true;
      });
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },

    saveFold(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.fileId) {
            // 修改
            editFile(this.form).then((res) => {
              this.getFilesData(this.parentFolder);
              this.form = {};
              this.foldShow = false;
              this.$message({
                message: "名称修改成功!",
                type: "success",
              });
            });
          } else {
            // 新建
            this.form.userId = this.$route.params.userId;
            this.form.parentFolder = this.parentFolder;
            saveCeaterFold(this.form).then((res) => {
              this.getFilesData(this.parentFolder);
              this.form = {};
              this.foldShow = false;
              this.$message({
                message: "文件夹创建成功!",
                type: "success",
              });
            });
          }

          // alert('submit!');
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    openFold(id) {
      this.getFilesData(id);
    },
    startSearch(e) {
      this.getFilesData("", e);
      // console.log(e);
    },
    addFold() {
      this.foldTitle = "新建文件夹";
      this.form = {};
      this.foldShow = true;
    },
    handleEdit(item) {
      // console.log(item)
      // this.form =
      this.foldTitle = "修改文件名";
      this.form = JSON.parse(JSON.stringify(item));
      this.foldShow = true;
    },
    handleChange(file) {
      this.fileList = [file];
    },
    uploadVideoProcess(event, file, fileList) {
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 1000); // 一秒后关闭进度条
      }
    },

    handleDelete(id) {
      const taskIds = id || this.checkedCities;
      this.$confirm("是否确认删除此文件？", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delFile(taskIds).then(() => {
          this.getFilesData(this.parentFolder);
          this.$message({
            message: "删除文件成功!",
            type: "success",
          });
        });
      });
      // delFile(id).then(res=>{
      // 	if(res.code === 200){

      // 	}
      // })
    },
    openPage(url) {
      open(url);
    },
    openMenu(tag, e) {
      // console.log(tag, e);
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }

      this.top = e.clientY;
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu() {
      this.visible = false;
    },
    getIcon(type) {
      switch (type) {
        case "doc":
        case "docx":
          return "iconfont icon-file-word ";
          break;
        case "xls":
        case "xlsx":
          return "iconfont icon-file-excel excle";
          break;
        case "ppt":
        case "pptx":
          return "iconfont icon-file-ppt ppt";
          break;
        case "pdf":
        case "pdf":
          return "iconfont icon-pdf pdf";
          break;
        case "gif":
        case "jpg":
        case "jpeg":
        case "png":
          return "el-icon-picture-outline";
          break;
      }
    },
    openFile(file) {
      // console.log(file);
      if (
        ["doc", "docx", "xls", "xlsx", "ppt", "pptx"].indexOf(file.ext) != -1
      ) {
        this.title = file.fileName;
        // this.fileUrl =
        // 	"http://onlinefile.wantuyun.cn/onlinePreview?url=" +
        // 	encodeURIComponent(Base64.encode(file.url));
        this.fileUrl =
          "https://view.officeapps.live.com/op/view.aspx?src=" + file.url;
        this.dialogVisible = true;
        // window.open(
        // 	// process.env.VUE_APP_BASE_API +
        // 	// "/user/file/onlinePreview?fileId=" +
        // 	// file.fileId
        // );
        return;
      }
      if (["gif", "jpg", "jpeg", "png"].indexOf(file.ext) != -1) {
        this.previewImages = [file.url];
        this.showPreview = true;
        return;
      }
      if (["pdf"].indexOf(file.ext) != -1) {
        this.title = file.fileName;
        this.fileUrl = file.url;
        // "http://onlinefile.wantuyun.cn/onlinePreview?url=" +
        // encodeURIComponent(Base64.encode(file.url));
        this.dialogVisible = true;
        return;
      }
    },
    closeViewer() {
      this.showPreview = false;
    },

    beforeAvatarUpload(file, id = "") {
      // console.log(file);
      // 创建axios实例
      this.loading = true;
      const instance = axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        baseURL: process.env.VUE_APP_BASE_API,
      });
      var data = new FormData();
      data.append("file", file);
      data.append("userId", this.$route.params.userId);
      data.append("parentFolder", this.parentFolder);
      instance({
        url: "/api/front/user/file/upload",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data;",
          Authorization: "Bearer " + this.Storage.get("token"),
        },
        data: data,
      }).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.getFilesData(this.parentFolder);
          this.$message({
            message: "文件上传成功!",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
      return false;
    },
    getFilesData(id = "", fileName = "") {
      this.loading = true;
      getFiles({
        userId: this.$route.params.userId,
        parentFolder: id,
        fileName,
      }).then((res) => {
        this.loading = false;
        // console.log(res);
        // res.data.files.forEach((item) => {
        // 	// 按日分
        // 	// item.createTime=item.createTime.split(' ')[0]

        // 	// 按月份
        // 	// item.createTime = item.createTime.substr(0, 7);
        // 	// console.log(item.createTime);
        // });
        this.isIndeterminate = false;
        this.checkedCities = [];
        this.parentFolder = id;
        this.path = res.data.path;
        this.fileList = res.data.files; //this.dataResort(res.data.files);
        this.cities = res.data.files.map((item) => item.fileId);
      });
    },
    // 前端分类
    dataResort(data) {
      let newArr = [];
      data.forEach((item, i) => {
        let index = -1;
        let isExists = newArr.some((newItem, j) => {
          if (item.createTime == newItem.date) {
            index = j;
            return true;
          }
        });
        // 根据some方法返回的结果 分别push
        if (!isExists) {
          newArr.push({
            date: item.createTime,
            subList: [item],
          });
        } else {
          newArr[index].subList.push(item);
        }
      });
      // 按照天排序
      // newArr.sort(function (a, b) {
      // 	return a.date < b.date ? 1 : -1;
      // })

      return newArr;
    },
  },

  created() {
    this.getFilesData(this.$route.params.folderId);
    this.getTemplateFileList();
  },
};
</script>
<style>
.el-tooltip__popper {
  font-size: 25px;
}

.el-divider {
  background-color: #000;
  margin: 10px 0;
}

.el-dialog {
  display: flex;
  flex-direction: column;
}

.el-dialog__body {
  height: 100%;
}

.el-dialog__header button {
  font-size: 30px;
}

.box-card .el-card__header {
  padding: 10px 20px;
}
</style>
<style lang="scss" scoped>
.el-checkbox-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

::v-deep .file-box .el-checkbox {
  transition: background 0.5s;

  &:hover {
    border-radius: 10px;
    background: rgba($color: #fff, $alpha: 0.8);
    box-shadow: 0 0 10px rgba($color: #ccc, $alpha: 0.8);

    .el-checkbox__input {
      // display: block;
    }
  }

  padding-top: 20px;
  padding-right: 20px;
}

::v-deep .file-box .el-checkbox__label {
  padding-left: 20px;
}

::v-deep .file-box .el-checkbox__input {
  transform: scale(1.5);
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
  // display: none;
  // opacity: 0;
}

::v-deep .el-checkbox,
.el-checkbox__input {
  white-space: pre-wrap;
}

// .el-checkbox.is-bordered+.el-checkbox.is-bordered
.el-breadcrumb ::v-deep .el-breadcrumb__inner {
  color: #1890ff !important;
  // font-weight:400 !important;
  cursor: pointer !important;
}

.el-breadcrumb__item:first-child ::v-deep .el-breadcrumb__inner {
  font-weight: bold !important;
}

.el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
  color: #333 !important;
}

::v-deep .el-breadcrumb__inner {
  &:hover {
    // font-size: ;
    text-decoration: underline;
    cursor: pointer;
  }
}

::v-deep .el-dialog {
  margin-top: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f8 !important;
}

@keyframes bganimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.back {
  width: 80px;
  height: 80px;
  position: absolute;
  cursor: pointer;
  left: 50px;
  top: 50px;

  img {
    width: 100%;
    height: 100%;
  }
}

.container {
  background-size: 1000%;
  animation: bganimation 60s infinite;
  background-attachment: fixed;
  background-image: linear-gradient(
    125deg,
    #e4ffcd,
    #6dd5fa,
    rgba(34, 209, 113, 0.3),
    #e4ffcd,
    #c7f1e7,
    #b9cfff
  );
  width: 100vw;
  height: 100vh;
}

.file-list {
  overflow: auto;
  width: 80%;
  height: 80%;
  position: absolute;
  background-color: rgba(228, 239, 255, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);

  .nav {
    padding: 10px 40px;
    display: flex;
    align-items: center;
  }

  .file-content {
    padding: 20px 40px 30px;

    .file-check {
      margin-bottom: 30px;
      border-bottom: 1px solid #ccc;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
    }

    .date {
      font-size: 30px;
    }
  }
}

.file-box {
  width: 100%;

  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;

  // i {
  // 	width: 100px;
  // 	margin: 10px 30px 0 0;
  // }
}

.file {
  width: 100px;
  height: 150px;
  // margin: 10px 30px 0 0;

  .file-icon {
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    i {
      margin: auto;
      width: auto;
      font-size: 50px;
      color: #409eff;
    }

    .excle {
      color: green;
    }

    .ppt {
      color: orange;
    }

    .pdf {
      color: red;
    }
  }

  .file-name {
    // text-indent: 1em;
    padding: 8px 0 0;
    font-size: 16px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* 这里是超出几行省略 */
    overflow: hidden;
  }
}

.upload-template {
  padding: 30px 40px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .box-card {
    width: calc(100% - 200px);
    background-color: rgba(255, 255, 255, 0.5);
  }

  .search {
    display: flex;

    .fold {
      margin-right: 30px;
    }
  }

  .upload-file {
    width: 120px;
    // height: 120px;
    // margin: 10px 30px 0px 0px;

    .file-icon {
      margin-top: 30px;

      box-shadow: 0 0 10px rgb(0 0 0 / 10%);
      overflow: hidden;
      cursor: pointer;
      width: 100%;
      height: 120px;
      background-color: #f8f8f8;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      span {
        font-size: 10px;
        color: #409eff;
        font-weight: bold;
      }
    }
  }
}

.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
}

.full-screen {
  position: absolute;
  top: 20px;
  right: 60px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  color: #909399;
}
</style>
